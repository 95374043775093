import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../Pages/Dashboard/DashboardWrapper'
import {MenuTestPage} from '../Pages/MenuTestPage'
import {getRandomKey} from '../Helpers/Default'
import VoteDetail from '../Pages/Vote/Detail'
import {useSvjSessionUserIdQueryParam} from '../Hooks/SvjUrl'
import SurveyResult from '../Pages/Survey/Result'
import OrderAdmin from '../Pages/Admin/Order/intex'
import AdminOrderForm from '../Pages/Admin/Order/form'
import AdminOrderCheck from '../Pages/Admin/Order/check'

export function PrivateRoutes() {
  useSvjSessionUserIdQueryParam()
  const BuilderPageWrapper = lazy(() => import('../Pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const MessageFormPage = lazy(() => import('../Pages/Messages/Form'))
  const MessagesPage = lazy(() => import('../Pages/Messages'))
  const CalendarPage = lazy(() => import('../Pages/Calendar'))
  const CalendarForm = lazy(() => import('../Pages/Calendar/Form'))
  const DocumentsPage = lazy(() => import('../Pages/Documents'))
  const DocumentDetailPage = lazy(() => import('../Pages/Documents/Detail'))
  const RoomsPage = lazy(() => import('../Pages/Rooms'))
  const RoomFormPage = lazy(() => import('../Pages/Rooms/Form'))
  const UsersPage = lazy(() => import('../Pages/Users'))
  const UserFormPage = lazy(() => import('../Pages/Users/Form'))
  const Reports = lazy(() => import('../Pages/Reports'))
  const ReportForm = lazy(() => import('../Pages/Reports/Form'))
  const ReportDetail = lazy(() => import('../Pages/Reports/Detail'))
  const Survey = lazy(() => import('../Pages/Survey'))
  const SurveyForm = lazy(() => import('../Pages/Survey/Form'))
  const SurveyDetail = lazy(() => import('../Pages/Survey/Detail'))
  const SurveyResult = lazy(() => import('../Pages/Survey/Result'))
  const Vote = lazy(() => import('../Pages/Vote'))
  const VoteForm = lazy(() => import('../Pages/Vote/Form'))
  const MeetingOfOwners = lazy(() => import('../Pages/MeetingOfOwners'))
  const MeetingOfOwnersForm = lazy(() => import('../Pages/MeetingOfOwners/Form'))
  const GalleryForm = lazy(() => import('../Pages/Gallery/Form'))
  const Gallery = lazy(() => import('../Pages/Gallery'))
  const GalleryDetail = lazy(() => import('../Pages/Gallery/Detail'))
  const Svj = lazy(() => import('../Pages/Svj'))
  const Contact = lazy(() => import('../Pages/Contact'))
  const ContactForm = lazy(() => import('../Pages/Contact/Form'))
  const CashDesk = lazy(() => import('../Pages/CashDesk'))
  const CashDeskForm = lazy(() => import('../Pages/CashDesk/Form'))
  const SvjFee = lazy(() => import('../Pages/SvjFee'))
  const SvjFeeForm = lazy(() => import('../Pages/SvjFee/Form'))
  const RequirementForApproval = lazy(() => import('../Pages/RequirementForApproval'))
  const RequirementForApprovalForm = lazy(() => import('../Pages/RequirementForApproval/Form'))
  const RequirementForApprovalDetail = lazy(() => import('../Pages/RequirementForApproval/Detail'))
  const Discussion = lazy(() => import('../Pages/Discussion'))
  const DiscussionForm = lazy(() => import('../Pages/Discussion/Form'))
  const DiscussionDetail = lazy(() => import('../Pages/Discussion/Detail'))
  const RoleForm = lazy(() => import('../Pages/Svj/RightSide/Roles/Form'))
  const Error404 = lazy(() => import('../modules/errors/components/Error404'))
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route key={getRandomKey()} path={`/nastenka`} component={DashboardWrapper} />
        <Route key={getRandomKey()} path={`/sousede/:id`} component={UserFormPage} />
        <Route key={getRandomKey()} path={`/sousede/novy`} component={UserFormPage} />
        <Route key={getRandomKey()} exact path={`/sousede`} component={UsersPage} />
        <Route key={getRandomKey()} path={`/zpravy/novy`} component={MessageFormPage} />
        <Route key={getRandomKey()} path={`/zpravy/:id`} component={MessageFormPage} />
        <Route key={getRandomKey()} path={`/zpravy`} component={MessagesPage} />
        <Route key={getRandomKey()} path={`/jednotky/novy`} component={RoomFormPage} />
        <Route key={getRandomKey()} path={`/jednotky/:id`} component={RoomFormPage} />
        <Route key={getRandomKey()} path={`/jednotky`} component={RoomsPage} />
        <Route key={getRandomKey()} path={`/ankety/novy`} component={SurveyForm} />
        <Route key={getRandomKey()} path={`/ankety/:id/vysledky`} component={SurveyResult} />
        <Route key={getRandomKey()} path={`/ankety/:id/upravit`} component={SurveyForm} />
        <Route key={getRandomKey()} path={`/ankety/:id`} component={SurveyDetail} />
        <Route key={getRandomKey()} path={`/ankety`} component={Survey} />
        <Route key={getRandomKey()} path={`/hlasovani/:id/upravit`} component={VoteForm} />
        <Route key={getRandomKey()} path={`/hlasovani/novy`} component={VoteForm} />
        <Route key={getRandomKey()} path={`/hlasovani/:id`} component={VoteDetail} />
        <Route key={getRandomKey()} path={`/hlasovani`} component={Vote} />
        <Route key={getRandomKey()} path={'/dokumenty/:id'} component={DocumentDetailPage} />
        <Route key={getRandomKey()} path={'/dokumenty'} component={DocumentsPage} />
        <Route key={getRandomKey()} path={`/kalendar-udalosti/:id`} component={CalendarForm} />
        <Route key={getRandomKey()} path={`/kalendar-udalosti/novy`} component={CalendarForm} />
        <Route path={`/kalendar-udalosti`} component={CalendarPage} />
        <Route key={getRandomKey()} path={`/hlaseni/novy`} exact={true} component={ReportForm} />
        <Route key={getRandomKey()} path={`/hlaseni/:id`} exact={true} component={ReportDetail} />
        <Route
          key={getRandomKey()}
          path={`/diskuze/novy`}
          exact={true}
          component={DiscussionForm}
        />
        <Route
          key={getRandomKey()}
          path={`/diskuze/:id`}
          exact={true}
          component={DiscussionDetail}
        />
        <Route path={`/diskuze`} component={Discussion} />
        <Route
          key={getRandomKey()}
          path={`/admin/objednavky`}
          exact={true}
          component={OrderAdmin}
        />
        <Route
          key={getRandomKey()}
          path={`/admin/objednavky/novy`}
          exact={true}
          component={AdminOrderForm}
        />
        <Route
          key={getRandomKey()}
          path={`/admin/objednavky/:id/kontrola`}
          exact={true}
          component={AdminOrderCheck}
        />

        <Route
          key={getRandomKey()}
          path={`/admin/objednavky/:id`}
          exact={true}
          component={AdminOrderForm}
        />

        {/*<Route*/}
        {/*  key={getRandomKey()}*/}
        {/*  path={`/hlaseni/:id/detail`}*/}
        {/*  exact={true}*/}
        {/*  component={ReportForm}*/}
        {/*/>*/}
        <Route key={getRandomKey()} path={`/hlaseni`} component={Reports} />
        <Route
          key={getRandomKey()}
          path={`/shromazdeni-vlastniku/novy`}
          component={MeetingOfOwnersForm}
        />
        <Route
          key={getRandomKey()}
          path={`/shromazdeni-vlastniku/:id`}
          component={MeetingOfOwnersForm}
        />
        <Route key={getRandomKey()} path={`/role/:id`} component={RoleForm} />
        <Route key={getRandomKey()} path={`/shromazdeni-vlastniku`} component={MeetingOfOwners} />
        <Route key={getRandomKey()} path={`/fotogalerie/novy`} component={GalleryForm} />
        <Route key={getRandomKey()} path={`/fotogalerie/:id/upravit`} component={GalleryForm} />
        <Route key={getRandomKey()} path={`/fotogalerie/:id`} component={GalleryDetail} />
        <Route key={getRandomKey()} path={`/fotogalerie`} component={Gallery} />
        <Route key={getRandomKey()} path={`/detail-svj`} component={Svj} />
        <Route key={getRandomKey()} path={`/adresar-kontaktu/:id`} component={ContactForm} />
        <Route key={getRandomKey()} path={`/adresar-kontaktu/novy`} component={ContactForm} />
        <Route key={getRandomKey()} path={`/adresar-kontaktu`} component={Contact} />
        <Route key={getRandomKey()} path={`/pokladna/:id`} component={CashDeskForm} />
        <Route key={getRandomKey()} path={`/pokladna/novy`} component={CashDeskForm} />
        <Route key={getRandomKey()} path={`/pokladna`} component={CashDesk} />
        <Route key={getRandomKey()} path={`/prehled-poplatku/:id`} component={SvjFeeForm} />
        <Route key={getRandomKey()} path={`/prehled-poplatku/novy`} component={SvjFeeForm} />
        <Route key={getRandomKey()} path={`/prehled-poplatku`} component={SvjFee} />

        <Route
          key={getRandomKey()}
          path={`/pozadavky-ke-schvaleni/novy`}
          component={RequirementForApprovalForm}
        />
        <Route
          key={getRandomKey()}
          path={`/pozadavky-ke-schvaleni/:id`}
          component={RequirementForApprovalDetail}
        />
        <Route
          key={getRandomKey()}
          path={`/pozadavky-ke-schvaleni`}
          component={RequirementForApproval}
        />

        <Route key={getRandomKey()} path='/builder' component={BuilderPageWrapper} />
        <Route key={getRandomKey()} path='/crafted/pages/profile' component={ProfilePage} />
        <Route key={getRandomKey()} path='/crafted/pages/wizards' component={WizardsPage} />
        <Route key={getRandomKey()} path='/crafted/widgets' component={WidgetsPage} />
        <Route key={getRandomKey()} path='/crafted/account' component={AccountPage} />
        <Route key={getRandomKey()} path='/apps/chat' component={ChatPage} />
        <Route key={getRandomKey()} path='/menu-test' component={MenuTestPage} />
        <Route key={getRandomKey()} path={'/nastenka'} component={DashboardWrapper} />
        <Route key={getRandomKey()} path='/404' component={Error404} />
        {/*<Redirect from='/auth' to='/dashboard' />*/}
        <Redirect key={getRandomKey()} from='/' to='/nastenka' />
      </Switch>
    </Suspense>
  )
}
